import React, { lazy, Suspense, useState } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import notification from '@iso/components/Notification';

import apiAccount from '@iso/services/repository/account'

const PDFView = lazy(() => import('./containers/PDF'));
const PDVView = lazy(() => import('./containers/PDV'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Board = lazy(() => import('./containers/ScrumBoard/PageBoard/Board'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  const [testLogin, setTestLogin] = useState(true);
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  apiAccount
    .checkSession()
    .then(response => {
      if (!response.data.success) {
        notification('error','A sessão foi encerrada');
        setTestLogin(false);
        // localStorage.clear();
        sessionStorage.clear();
      }
    })
    .catch(() => {
      setTestLogin(false);
      console.log('Erro na verificação de sessão.');
    });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn && testLogin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/board/:id">
              <Board />
            </PrivateRoute>
            <Route path='/pdv-p7'>
              <PDVView />
            </Route>
            <PrivateRoute path="/pdf/:type/:reference">
              <PDFView />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}