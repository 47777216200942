import http from "../api";
// import axios from "axios";
// import { getBaseUrl } from "../../library/helpers/utility";

class AccountDataService {
    getSession() {
        return http({
            'method':'GET',
            'url':'/auth'
        });
    }

    checkSession() {
        return http({
            'method':'GET',
            'url':'/auth/session'
        });
    }

    getAllAccountsInfo() {
        return http({
            'method':'GET',
            'url':'/account/get_accounts_info/name/asc'
        });
    }

    getAccountData() {
        return http({
            'method':'GET',
            'url':'/account/my_account'
        });
    }

    sendEmail(login) {
        return http({
            'url':'/account/sendEmail/'+login,
            'method':'GET'
        });
    }

    verificarHash(hash) {
        return http({
            'url':'/account/verificarHash/'+hash,
            'method':'GET'
        });
    }

    alterarSenhaUser(data) {
        return http({
            'url':'/account/alterarSenhaUser',
            'method':'POST',
            'data': data
        });
    }

    changeAccountMasterData(data) {
        return http({
            'url':'/account/changeAccountMasterData',
            'method':'POST',
            'data': data
        });
    }

    async doSignIn(data) {
        return http({
            'method':'POST',
            'url':'/auth/login',
            'data': data
        });
    }

    doSignOff() {
        return http({
            'method':'POST',
            'url':'/auth/logoff'
        });
    }

    async validate(data) {
        return http({
            'method':'POST',
            'url':'account/validate',
            'data': data
        });
    }

    // createAccount(data) {
    //     return http({
    //         'url':'/account',
    //         'method':'POST',
    //         'data': data
    //     });
    // }
}

export default new AccountDataService();